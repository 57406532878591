<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <!-- <b-link  to="/users" > -->
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-primary`" size="45">
              <feather-icon size="21" :icon="'UsersIcon'" />
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ data.users }}
              </h2>
              <span> {{ $t("Total") }} {{ $t("Users") }} </span>
            </div>
          </b-card-body>
        </b-card>
        <!-- </b-link> -->
      </div>
      <div class="col-md-3">
        <!-- <b-link  to="/centers" > -->
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-warning`" size="45">
              <i class="fas fa-building" style="font-size: 18px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ data.center }}
              </h2>
              <span> {{ $t("Total") }} {{ $t("Centrals") }} </span>
            </div>
          </b-card-body>
        </b-card>
        <!-- </b-link> -->
      </div>
      <div class="col-md-3">
        <!-- <b-link  to="/units" > -->
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-success`" size="45">
              <feather-icon size="21" :icon="'TagIcon'" />
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ data.units }}
              </h2>
              <span> {{ $t("Total") }} {{ $t("Units") }} </span>
            </div>
          </b-card-body>
        </b-card>
        <!-- </b-link> -->
      </div>
      <div class="col-md-3">
        <!-- <b-link  to="/professionals" > -->
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-danger`" size="45">
              <i class="fas fa-stethoscope" style="font-size: 18px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ data.professionals }}
              </h2>
              <span> {{ $t("Total") }} {{ $t("Professionals") }} </span>
            </div>
          </b-card-body>
        </b-card>
        <!-- </b-link> -->
      </div>
      <div class="col-md-3">
        <!-- <b-link  to="/insurances" > -->
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-success`" size="45">
              <feather-icon size="21" :icon="'ShieldIcon'" />
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ data.autoInsurance }}
              </h2>
              <span>{{ $t("Total") }} {{ $t("Insurance") }} </span>
            </div>
          </b-card-body>
        </b-card>
        <!-- </b-link> -->
      </div>
      <div class="col-md-3">
        <!-- <b-link  to="/fines" > -->

        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-danger`" size="45">
              <feather-icon size="21" :icon="'FileTextIcon'" />
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ data.fine }}
              </h2>
              <span> {{ $t("Total") }} {{ $t("Fines") }} </span>
            </div>
          </b-card-body>
        </b-card>
        <!-- </b-link> -->
      </div>
      <div class="col-md-3">
        <!-- <b-link  to="/devices" > -->
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-info`" size="45">
              <feather-icon size="21" :icon="'CpuIcon'" />
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ data.device }}
              </h2>
              <span>{{ $t("Total") }} {{ $t("Devices") }} </span>
            </div>
          </b-card-body>
        </b-card>
        <!-- </b-link> -->
      </div>
      <div class="col-md-3">
        <!-- <b-link  to="/licensings" > -->

        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-info`" size="45">
              <feather-icon size="21" :icon="'CreditCardIcon'" />
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ data.licensing }}
              </h2>
              <span> {{ $t("Total") }} {{ $t("Licensing") }} </span>
            </div>
          </b-card-body>
        </b-card>
        <!-- </b-link> -->
      </div>

      <div class="col-md-3">
        <!-- <b-link  to="/refuelings" > -->

        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-warning`" size="45">
              <feather-icon size="21" :icon="'DollarSignIcon'" />
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ data.refueling }}
              </h2>
              <span> {{ $t("Total") }} {{ $t("Supplies") }} </span>
            </div>
          </b-card-body>
        </b-card>
        <!-- </b-link> -->
      </div>

      <div class="col-md-3">
        <!-- <b-link  to="/vehicles" > -->

        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-danger`" size="45">
              <feather-icon size="21" :icon="'TruckIcon'" />
            </b-avatar>

            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ data.vehicle }}
              </h2>
              <span> {{ $t("Total") }} {{ $t("Vehicles") }} </span>
            </div>
          </b-card-body>
        </b-card>
        <!-- </b-link> -->
      </div>
      <div class="col-md-3">
        <!-- <b-link  to="/vehicles" > -->

        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-secondary`" size="45">
              <feather-icon size="21" :icon="'TruckIcon'" />
            </b-avatar>

            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ data.vehicle_free }}
              </h2>
              <span> {{ $t("Vehicles") }} {{ $t("Free") }} </span>
            </div>
          </b-card-body>
        </b-card>
        <!-- </b-link> -->
      </div>
      <div class="col-md-3">
        <!-- <b-link  to="/vehicles" > -->

        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-info`" size="45">
              <feather-icon size="21" :icon="'TruckIcon'" />
            </b-avatar>

            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ data.vehicle_courtyard }}
              </h2>
              <span> {{ $t("Vehicles") }} {{ $t("Courtyard") }} </span>
            </div>
          </b-card-body>
        </b-card>
        <!-- </b-link> -->
      </div>
      <div class="col-md-3">
        <!-- <b-link  to="/vehicles" > -->

        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-danger`" size="45">
              <feather-icon size="21" :icon="'TruckIcon'" />
            </b-avatar>

            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ data.vehicle_occurrence }}
              </h2>
              <span> {{ $t("Vehicles") }} {{ $t("Occurrence") }} </span>
            </div>
          </b-card-body>
        </b-card>
        <!-- </b-link> -->
      </div>
      <div class="col-md-3">
        <!-- <b-link  to="/vehicles" > -->
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-warning`" size="45">
              <feather-icon size="21" :icon="'TruckIcon'" />
            </b-avatar>

            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ data.vehicle_under_maintenance }}
              </h2>
              <span> {{ $t("Vehicles") }} {{ $t("Under maintenance") }} </span>
            </div>
          </b-card-body>
        </b-card>
        <!-- </b-link> -->
      </div>
    </div>
  </div>
</template>

<script>
import { BCardText, BLink, BCard, BCardBody, BAvatar } from "bootstrap-vue";

export default {
  data() {
    return {
      data: {
        vehicle: 0,
        center: 0,
        device: 0,
        fine: 0,
        licensing: 0,
        units: 0,
        refueling: 0,
        users: 0,
        professionals: 0,
        autoInsurance: 0,
        vehicle_free: 0,
        vehicle_courtyard: 0,
        vehicle_occurrence: 0,
        vehicle_under_maintenance: 0,
      },
    };
  },
  components: {
    BCard,
    BCardText,
    BLink,
    BCard,
    BCardBody,
    BAvatar,
  },
  methods: {
    getDashboard() {
      const self = this;
      const api = self.$store.state.api + "companies/report";

      axios
        .post(api)
        .then((response) => {
          self.data = response.data;
        })
        .catch((error) => {
          this.$error(error);
        });
    },
  },
  mounted() {
    this.getDashboard();
  },
  created() {},
};
</script>

<style></style>
